<template>
    <div v-if="event && event.length>0">
        <b-button id="add-to-calendar" variant="link" class="m-0 p-1">
            <font-awesome-icon :icon="['fal', 'calendar-plus']" size="lg" />
        </b-button>
        <b-popover target="add-to-calendar" custom-class="rounded-lg" triggers="hover">
            <template #default>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/apple`" target="_blank" rel><b-img :src="appleLogo" width="24" height="24" /> Apple</b-button>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/google`" target="_blank" rel><b-img :src="googleLogo" width="24" height="24" /> Google <span class="text-muted small">(online)</span></b-button>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/outlook365`" target="_blank" rel><b-img :src="office365Logo" width="24" height="24" /> Office 365 <span class="text-muted small">(online)</span></b-button>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/outlook`" target="_blank" rel><b-img :src="outlookLogo" width="24" height="24" /> Outlook</b-button>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/outlooklive`" target="_blank" rel><b-img :src="outlookLiveLogo" width="24" height="24" /> Outlook.com <span class="text-muted small">(online)</span></b-button>
                <b-button variant="link-dark" block class="border-0 m-0 px-0 text-left" :href="`https://calendar.hummz.events/${event}/yahoo`" target="_blank" rel><b-img :src="yahooLogo" width="24" height="24" /> Yahoo <span class="text-muted small">(online)</span></b-button>
            </template>
        </b-popover>
    </div>
</template>

<script>
import { BButton, BPopover, BListGroup, BListGroupItem, BImg } from 'bootstrap-vue'

export default {
    props: {
        event: {
            type: String,
            default: '',
        },
    },
    components: {
        BButton,
        BPopover,
        BListGroup,
        BListGroupItem,
        BImg,
    },
    data() {
        return {
            appleLogo: require('@/assets/images/calendar/apple.png'),
            googleLogo: require('@/assets/images/calendar/google.png'),
            office365Logo: require('@/assets/images/calendar/office.png'),
            outlookLogo: require('@/assets/images/calendar/outlook.png'),
            outlookLiveLogo: require('@/assets/images/calendar/outlooklive.png'),
            yahooLogo: require('@/assets/images/calendar/yahoo.png'),
        }
    },
}
</script>
