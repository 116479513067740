<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-start p-0 m-0 bg-white">
        <swiper
          v-if="pages && pages.login && pages.login.banners && pages.login.banners.length>1"
          effect="fade"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(banner, index) in pages.login.banners" :key="index">
            <b-img-lazy :src="banner.image" fluid style="height:100vh !important;"/>
          </swiper-slide>
        </swiper>
        <div v-if="pages && pages.login && pages.login.banners && pages.login.banners.length===1">
          <b-img-lazy :src="pages.login.banners[0].image" fluid style="height:100vh !important;"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <b-col
        cols=12
        class="d-lg-none d-flex align-items-center p-0 m-0 bg-white"
      >
        <swiper
          v-if="pages && pages.login && pages.login.smbanners"
          effect="fade"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(banner, index) in pages.login.smbanners" :key="index">
            <b-img-lazy :src="banner.image" fluid/>
          </swiper-slide>
        </swiper>
      </b-col>

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex flex-column justify-content-center align-items-center auth-bg px-2 p-0 m-0 bg-white"
      >
        <b-card
          class="mx-auto w-100 border-primary rounded-lg shadow "
        >
          <b-card-title
            class="mb-1 font-weight-bold text-center"
            title-tag="h2"
          >
            <div class="d-block">
              <b-img-lazy v-if="event.logo || event.organizer.logo" :src="event.logo || event.organizer.logo" class="img-fluid" style="width: 40%" />
            </div>
            <span class="text-muted">Welcome to</span><br/>{{event.title}}
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter your email and start engaging
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <feather-icon
              v-b-tooltip.hover.left="'Enter the email you registered with.'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10px; right: 10px;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="your.name@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="d-none">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules=""
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <span v-if="eventMode === 'login'">Check In</span><span v-else>Register</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="d-none text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div> -->
        </b-card>
        <!-- Event organizers/hosts -->
        <!-- Multiple organizers/hosts-->
        <b-card v-if="event && event.organizer && Array.isArray(event.organizer)" class="mx-auto w-100 p-1 d-flex flex-column justify-content-between rounded-lg border-primary" no-body>
          <b-card-body class="p-0 m-0">
            <h6 class="font-weight-bolder">HOSTED BY</h6>
            <div v-for="(organizer, index) in event.organizer" :key="index" class="mb-2">
              <b-img-lazy v-if="organizer.logo" :src="organizer.logo" :alt="organizer.name" thumbnail width="80px" right class="shadow"/>
              <h1>{{ organizer.name }}</h1>
              <h5 v-if="organizer.contactDesignation">{{ organizer.contactDesignation }}</h5>
              <h6 v-if="organizer.contactAddress">{{organizer.contactAddress}}</h6>
              <h6 v-if="organizer.contactPhone">{{organizer.contactPhone}}</h6>

              <div v-if="organizer.social">
                <b-link v-if="organizer.website" :href="organizer.website" target="_blank">
                  <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-if="organizer.contactEmail" :href="'mailto:' + organizer.contactEmail" target="_blank">
                  <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-for="socialLink in Object.keys(organizer.social)" :key="socialLink" :href="organizer.social[socialLink]" target="_blank">
                  <font-awesome-icon :icon="['fab', socialLink]" size="lg" class="mr-1" />
                </b-link>
              </div>
              <div v-else>
                <b-link v-if="organizer.website" :href="organizer.website" target="_blank">
                  <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-if="organizer.contactEmail" :href="'mailto:' + organizer.contactEmail" target="_blank">
                  <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                </b-link>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <!-- /Multiple organizers/hosts-->
        <!-- Single organizer/host-->
        <b-card v-if="event && event.organizer && !Array.isArray(event.organizer)" class="mx-auto w-100 p-1 d-flex flex-column justify-content-between rounded-lg border-primary" no-body>
          <b-card-body class="p-0 m-0">
            
            <h6 class="font-weight-bolder">HOSTED BY</h6>
            <b-img-lazy v-if="event.organizer.logo" :src="event.organizer.logo" :alt="event.organizer.name" thumbnail width="80px" right class="shadow"/>
            <h1>{{ event.organizer.name }}</h1>
            <h5 v-if="event.organizer.contactDesignation">{{ event.organizer.contactDesignation }}</h5>
            <h6 v-if="event.organizer.contactAddress">{{event.organizer.contactAddress}}</h6>
            <h6 v-if="event.organizer.contactPhone">{{event.organizer.contactPhone}}</h6>

            <div v-if="event.organizer.social">
              <b-link v-if="event.organizer.website" :href="event.organizer.website" target="_blank">
                <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
              </b-link>
              <b-link v-if="event.organizer.contactEmail" :href="'mailto:' + event.organizer.contactEmail" target="_blank">
                <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
              </b-link>
              <b-link v-for="socialLink in Object.keys(event.organizer.social)" :key="socialLink" :href="event.organizer.social[socialLink]" target="_blank">
                <font-awesome-icon :icon="['fab', socialLink]" size="lg" class="mr-1" />
              </b-link>
            </div>
            <div v-else>
              <b-link v-if="event.organizer.website" :href="event.organizer.website" target="_blank">
                <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
              </b-link>
              <b-link v-if="event.organizer.contactEmail" :href="'mailto:' + event.organizer.contactEmail" target="_blank">
                <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
              </b-link>
            </div>
          </b-card-body>
        </b-card>
        <!-- /Single organizer/host-->
        <!-- /Event organizers/hosts -->
        <div class="w-100 text-center">
          Powered by
          <b-img-lazy src="https://cdn.humz.it/hummz/hummz-name-color.svg" alt="Hummz" height="25px" width="auto" />
        </div>
      </b-col>
    <!-- /Login-->
    </b-row>
    <b-modal
      id="modal-register"
      centered
      cancel-disabled
      :title="event.title"
      size="lg"
      footer-class="d-none"
    >
      <h2>Register</h2>
      <p class="text-muted">Fields marked with an * are required.</p>
      <validation-observer ref="registerForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="register" v-if="event.slug !=='QpmMg3RPlZwgPlt56PvxJl'">
          <b-row>
            <b-col cols="12" lg="3">
              <b-form-group label="Title *" label-for="register-title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-select
                    id="register-title"
                    v-model="booking.title"
                    label="title"
                    :options="[
                      { value: null, text: '' },
                      { value: 'Mx', text: 'Mx' },
                      { value: 'Ms', text: 'Ms' },
                      { value: 'Mr', text: 'Mr' },
                      { value: 'Dr', text: 'Dr' },
                      { value: 'Prof', text: 'Prof' },
                    ]"
                    name="register-title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                label="First Name *"
                label-for="register-firstname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  vid="firstname"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="booking.firstname"
                    :state="errors.length > 0 ? false : null"
                    name="register-firstname"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                label="Middle Name"
                label-for="register-middlename"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Middle Name"
                  vid="middlename"
                  rules=""
                >
                  <b-form-input
                    id="register-middlename"
                    v-model="booking.middlename"
                    :state="errors.length > 0 ? false : null"
                    name="register-middlename"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group
                label="Last Name *"
                label-for="register-lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  vid="lastname"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastname"
                    v-model="booking.lastname"
                    :state="errors.length > 0 ? false : null"
                    name="register-lastname"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <!-- email -->
              <b-form-group label="Email *" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="booking.email"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    placeholder="your.name@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Mobile" label-for="register-mobile">
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  vid="tel"
                  rules=""
                >
                  <b-form-input
                    id="register-mobile"
                    v-model="booking.mobile"
                    :state="errors.length > 0 ? false : null"
                    name="register-mobile"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Organization"
                label-for="register-organization"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Organization"
                  vid="organization"
                  rules=""
                >
                  <b-form-input
                    id="register-organization"
                    v-model="booking.organization"
                    :state="errors.length > 0 ? false : null"
                    name="register-organization"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Job Title"
                label-for="register-designation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Job Title"
                  vid=""
                  rules=""
                >
                  <b-form-input
                    id="register-designation"
                    v-model="booking.designation"
                    :state="errors.length > 0 ? false : null"
                    name="register-designation"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="float-right"
          >
            Register
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            class="mr-1 float-right"
            @click="hideRegisterModal"
          >
            Cancel
          </b-button>
        </b-form>

        <b-form class="auth-login-form mt-2" @submit.prevent="register" v-if="event.slug==='QpmMg3RPlZwgPlt56PvxJl'">
          <b-row>
            <!-- <b-col cols="12" lg="3">
              <b-form-group label="Title *" label-for="register-title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-select
                    id="register-title"
                    v-model="booking.title"
                    label="title"
                    :options="[
                      { value: null, text: '' },
                      { value: 'Mx', text: 'Mx' },
                      { value: 'Ms', text: 'Ms' },
                      { value: 'Mr', text: 'Mr' },
                    ]"
                    name="register-title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="First Name *"
                label-for="register-firstname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  vid="firstname"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="booking.firstname"
                    :state="errors.length > 0 ? false : null"
                    name="register-firstname"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="12" lg="3">
              <b-form-group
                label="Middle Name"
                label-for="register-middlename"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Middle Name"
                  vid="middlename"
                  rules=""
                >
                  <b-form-input
                    id="register-middlename"
                    v-model="booking.middlename"
                    :state="errors.length > 0 ? false : null"
                    name="register-middlename"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="Last Name *"
                label-for="register-lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  vid="lastname"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastname"
                    v-model="booking.lastname"
                    :state="errors.length > 0 ? false : null"
                    name="register-lastname"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="12">
              <!-- email -->
              <b-form-group label="Email *" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="booking.email"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    placeholder="your.name@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="12" lg="6">
              <b-form-group label="Mobile" label-for="register-mobile">
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  vid="tel"
                  rules=""
                >
                  <b-form-input
                    id="register-mobile"
                    v-model="booking.mobile"
                    :state="errors.length > 0 ? false : null"
                    name="register-mobile"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>

          <!-- <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Organization"
                label-for="register-organization"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Organization"
                  vid="organization"
                  rules=""
                >
                  <b-form-input
                    id="register-organization"
                    v-model="booking.organization"
                    :state="errors.length > 0 ? false : null"
                    name="register-organization"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Job Title"
                label-for="register-designation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Job Title"
                  vid=""
                  rules=""
                >
                  <b-form-input
                    id="register-designation"
                    v-model="booking.designation"
                    :state="errors.length > 0 ? false : null"
                    name="register-designation"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="float-right"
          >
            Register
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            class="mr-1 float-right"
            @click="hideRegisterModal"
          >
            Cancel
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-thankyou"
      centered
      :title="event.title"
      size="lg"
      ok-title="Close"
      ok-variant="primary"
      ok-only
    >
      <h4>Thank you registering for the {{event.title}}.</h4>
      <br/>
      <h5 v-if="event && event.meta && event.meta.website">For more information about the {{event.title}}, please visit <a :href="event.meta.website" target="_blank">{{event.meta.website}}</a></h5>
     </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import posthog from 'posthog-js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import HummzLogo from '@/layouts/components/HummzLogo.vue'
import {
  BFormRow, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormSelect, BCardText, BCardTitle, BImgLazy, BForm, BButton, BAlert, BModal, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import api from '@/store/api/api';
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    Swiper,
    SwiperSlide,
    BFormRow,
    BRow,
    BCol,
    BLink,
    // BCarousel,
    // BCarouselSlide,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BFormSelect,
    BCardText,
    BCardTitle,
    BImgLazy,
    BForm,
    BButton,
    BAlert,
    BModal,
    ValidationProvider,
    ValidationObserver,
    HummzLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      eventMode: '',
      status: '',
      password: '',
      username: '',
      booking: {
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile: '',
        organization: '',
        designation: ''
      },      
      requiresRegistration: true,
      
      // validation rules
      required,
      email,
      // event: store.state.event.event,
      pages: store.state.event.pages,
      swiperOptions: {
        loop: true,
        effect: 'fade',
        autoHeight: false,
        grabCursor: true,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  computed: {
    eventMode() {
      let _eventMode = this.mode
      if (this.mode === 'register' && this.$date().isAfter(this.event.scheduledJoin)) {
        _eventMode = 'login'
      }
      return _eventMode
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    event() {
      return store.state.event.event;
    },
  },
  watch: {
    event() {
      let eventMode = ''
      // console.log('watch event params', this.$route.params);
      if (this.$route.query && this.$route.query.p) {
        // preview param set. compare preview key and redirect to login
        // this.$router.push({ name: 'auth-login' })
        if (store.state.event?.event?.meta?.preview && store.state.event?.event?.meta?.preview === this.$route.query.p) {
          this.eventMode = 'login'
        }
      } else if (store.state.event.event && store.state.event.event.scheduledStart) {
        let startDate = this.$date(store.state.event.event.scheduledJoin || store.state.event.event.scheduledStart)
        // console.log('home-event', startDate)
        if (this.$date().isBefore(startDate)) {
          // this.$router.replace({ name: 'auth-register' })
          eventMode = 'register'
        } else {
          // this.$router.replace({ name: 'auth-login' })
          eventMode = 'login'
        }
      } else {
        eventMode = 'search'
      }
      if (eventMode !== 'login') {
        console.log('calling redirect to register')
        this.redirectToRegister()
      }
      this.eventMode = eventMode
      // console.log('home-event', this.eventMode )
      this.$meta().refresh()
      this.pages = store.state.event.pages
      return store.getters["event/getEvent"]
    },
  },
  methods: {
    redirectToRegister() {
      console.log('redirecting to register')
      this.$router.replace('/')
    },
    showRegisterModal() {
      this.booking.email = this.username
      this.$bvModal.show('modal-register')
    },
    hideRegisterModal() {
      this.$bvModal.hide('modal-register')
    },
    async register() {
      // console.log('register', this.booking)
	    let location = {}
	    try {
		    // const response = await api.get('https://geolocation-db.com/json/');
		    // location = response.data;
		    // // console.log('location', location);
		    // if (location.country_code) {
			  //   this.booking.country = location.country_code
		    // }
        const response = await api.get('https://api.hummz.net/v2/countries/ip2location');
        location = response.data;
        if (location.countryCode) {
          this.booking.country = location.countryCode
        }
	    } catch (error) {
		    // report error
		    console.error('Location error', error);
	    }
      let bookingData = {
        data: this.booking,
        ticket: {
          type: "delegate",
          name: "Delegate",
        },
        meta: {
          urlParams: "",
          location: location,
          metadata: {
            timezone: this.$date.tz.guess(),
            offset: new Date().getTimezoneOffset(),
            referrer: document.referrer,
            userAgent: navigator.userAgent,
            pathName: window.location.href || "https://www.hummz.events",
            onLine: navigator.onLine,
          },
        },
      };
      // console.log('bookingData', bookingData)
        // create a new registration
      try {
        const bookingResponse = await api.post(`/events/register/${this.event.slug}`, bookingData)
        if (bookingResponse && !bookingResponse.data.error) {
          this.hideRegisterModal()
          this.username = this.booking.email
          this.password = ''
          this.login()
        } else {
          if (bookingResponse.data.error) {
            this.registationStatus = 'error'
            this.registrationResponse = bookingResponse.data.error
          }
        }
      } catch (error) {
        this.registationStatus = 'error'
        this.registrationResponse = error.message
      }
    },
    login() {
      // let location = {}
	    // try {
		  //   const response = await api.get('https://geolocation-db.com/json/');
		  //   location = response.data;
		  //   // console.log('location', location);
		  //   if (location.country_code) {
			//     this.booking.country = location.country_code
		  //   }
	    // } catch (error) {
		  //   // report error
		  //   console.error('Location error', error);
	    // }
      // console.log('login');
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // console.log('login success');
          store.dispatch('account/login', {email: this.username, password: this.password, eventId: this.event.id})
            .then((response) => {
              posthog.identify( this.$store.state.account.token, { name: this.$store.state.account.user.name , email: this.$store.state.account.user.email } );
              this.$ability.update([ { action: 'manage', subject: 'all', }, ])
              // login to event channel
              store.dispatch('joinEventChannel')
              // Redirect to default event navigation
              let defaultNavigation = '/reception'
              if (this.event.meta.defaultNavigation && this.event.meta.defaultNavigation.length>0) {
                defaultNavigation = this.event.meta.defaultNavigation
              }
              this.$router.replace(defaultNavigation).then(() => 
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-left',
                  props: {
                    title: `Welcome ${this.$store.state.account.user.name || this.$store.state.account.user.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully checked in. We invite you to start exploring!`,
                  },
                })
              )
            })
            .catch((error) => {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Registration Required`,
                    icon: 'StopIcon',
                    variant: 'error',
                    text: 'It seems you are not registered. Did you enter your registered email?',
                    position: 'bottom-left',
                  },
              })
              this.showRegisterModal()
            })
        }
      })
    },
  },
  beforeMount() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('hmze')
    store.dispatch("event/getEvent");
    store.dispatch('attendees/hideSidebar')
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: () => 'register'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/swiper.scss';
</style>
