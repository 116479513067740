<template>
  <section>
    <login-full-screen :mode="this.eventMode" v-if="pages.login.loginView==='fullscreen'" />
    <login-boxed :mode="this.eventMode" v-else />
  </section>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index";
import LoginBoxed from '@/views/pages/authentication/LoginBoxed.vue'
import LoginFullScreen from '@/views/pages/authentication/LoginFullScreen.vue'

export default {
  components: {
    LoginBoxed,
    LoginFullScreen
  },
  metaInfo() {
    return {
      title: 'Check-In'
    }
  },
  data() {
    return {
      eventMode: '',
      pages: store.state.event.pages,
    }
  },
  computed: {
    event() {
      return store.state.event.event;
    },
  },
  watch: {
    event() {
      // console.log('watch event params', this.$route.params);
      if (this.$route.query && this.$route.query.p) {
        // preview param set. compare preview key and redirect to login
        // this.$router.push({ name: 'auth-login' })
        if (store.state.event?.event?.meta?.preview && store.state.event?.event?.meta?.preview === this.$route.query.p) {
          this.eventMode = 'login'
        }
      } else if (store.state.event.event && store.state.event.event.scheduledStart) {
        let startDate = this.$date(store.state.event.event.scheduledJoin || store.state.event.event.scheduledStart)
        // console.log('home-event', startDate)
        if (this.$date().isBefore(startDate)) {
          // this.$router.replace({ name: 'auth-register' })
          this.eventMode = 'register'
        } else {
          // this.$router.replace({ name: 'auth-login' })
          this.eventMode = 'login'
        }
      } else {
        this.eventMode = 'search'
      }
      // console.log('home-event', this.eventMode )
      this.$meta().refresh()
      if (this.eventMode !== 'login') {
        this.$router.replace('/')
      }
      return store.getters["event/getEvent"];
    },
  },
  methods: {
    checkEventSlug() {
      // console.log('checkEventSlug params', this.$route);
      let eventSlug = '';
      if (this.$route.params && this.$route.params.slug) {
        eventSlug = this.$route.params.slug
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
        store.commit('event/SET_EVENT_SLUG', eventSlug)
        localStorage.setItem('hmzevent', eventSlug)
      }
      store.dispatch("event/getEvent", eventSlug);
    }
  },
  redirectToRegister() {
    this.$router.replace('/register')
  },
  beforeMount() {
    // console.log('beforeMount params', this.$route);
    this.checkEventSlug()
  },
  mounted() {
    if (window.fbq && store?.state?.event?.event?.meta?.fbPixels && Array.isArray(store.state.event.event.meta.fbPixels) && store.state.event.event.meta.fbPixels.length > 0) {
      // console.log(`setting fb pixel: ${store.state.event.event.title}`, fbq.getState())
      const fbqState = fbq.getState()
      if (!fbqState || !fbqState.pixels || !Array.isArray(fbq.pixels) || fbq.pixels.length === 0) {
        for (const pixel of store.state.event.event.meta.fbPixels) {
          fbq('init', pixel)
        }
        fbq('track', 'PageView');
      }
      // } else {
      //   console.log('fb pixel code not found', store.state.event.event, fbq.getState())
    }
  }
};
</script>