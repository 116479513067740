<template>
  <div class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Powered by 
      <b-link
        class="ml-25"
        :href="'https://www.hummz.com/?utm_source=' + hostname + '&utm_medium=web&utm_campaign=hummzevents'"
        target="_blank"
      >Hummz</b-link>&nbsp;
      <span class="d-none d-sm-inline-block">&copy; {{ new Date().getFullYear() }}, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Hand-crafted with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />&nbsp;&nbsp;in India
    </span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  computed: {
    hostname() {
      return window.location.hostname
    } 
  }
}
</script>
