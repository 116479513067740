var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.status > 0)?_vm._t("start-label",[(_vm.startLabel !== '' && _vm.tips && _vm.labelPosition === 'begin')?_c('span',[_vm._v(_vm._s(_vm.startLabel)+":")]):_vm._e(),(_vm.endLabel !== '' && !_vm.tips && _vm.labelPosition === 'begin')?_c('span',[_vm._v(_vm._s(_vm.endLabel)+":")]):_vm._e()],{"props":{
      tips: _vm.tips,
      startLabel: _vm.startLabel,
      endLabel: _vm.endLabel,
      labelPosition: _vm.labelPosition,
    }}):_vm._e(),(_vm.status > 0)?_vm._t("countdown",[(_vm.showDay)?_c('span',[_vm._v(_vm._s(_vm.days))]):_vm._e(),(_vm.showDay)?_c('i',[_vm._v(_vm._s(_vm.dayTxt))]):_vm._e(),(_vm.showHour)?_c('span',[_vm._v(_vm._s(_vm.hours))]):_vm._e(),(_vm.showHour)?_c('i',[_vm._v(_vm._s(_vm.hourTxt))]):_vm._e(),(_vm.showMinute)?_c('span',[_vm._v(_vm._s(_vm.minutes))]):_vm._e(),(_vm.showMinute)?_c('i',[_vm._v(_vm._s(_vm.minutesTxt))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.seconds))]),(_vm.secondsTxt)?_c('i',[_vm._v(_vm._s(_vm.secondsTxt))]):_vm._e()],{"props":{
      status: _vm.status,
      days: _vm.days,
      hours: _vm.hours,
      minutes: _vm.minutes,
      seconds: _vm.seconds,
      dayTxt: _vm.dayTxt,
      hourTxt: _vm.hourTxt,
      minutesTxt: _vm.minutesTxt,
      secondsTxt: _vm.secondsTxt,
      showDay: _vm.showDay,
      showHour: _vm.showHour,
      showMinute: _vm.showMinute,
    }}):_vm._e(),(_vm.status > 0)?_vm._t("end-label",[(_vm.startLabel !== '' && _vm.tips && _vm.labelPosition === 'end')?_c('span',[_vm._v(_vm._s(_vm.startLabel)+":")]):_vm._e(),(_vm.endLabel !== '' && !_vm.tips && _vm.labelPosition === 'end')?_c('span',[_vm._v(_vm._s(_vm.endLabel)+":")]):_vm._e()],{"props":{
      tips: _vm.tips,
      startLabel: _vm.startLabel,
      endLabel: _vm.endLabel,
      labelPosition: _vm.labelPosition,
    }}):_vm._e(),(_vm.status <= 0)?_vm._t("end-text",[_vm._v(" "+_vm._s(_vm.endText)+" ")],{"props":{ endText: _vm.endText }}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }